import { Link, graphql } from "gatsby";
import Layout from "../../components/layout";
import BlogList from "../../components/blogList";

function BlogIndex({ data }) {
  let blogData = data.allMarkdownRemark.edges;

  return (
    <Layout>
      <div id="blog-index">
        <h1>Blog</h1>
        <p id="all-tags">All tags</p>
        <BlogList posts={blogData} />
      </div>
    </Layout>
  );
}

export default BlogIndex;

export const query = graphql`
  query MyQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date
            tags
            title
          }
        }
      }
    }
  }
`
