import PostItem from "./postItem";

function BlogList(props) {
  var getList = props.posts.map((post, key) => {
    var flag = false;
    if (props.filter != undefined) {
      const i = post.node.frontmatter.tags.findIndex((t) => t == props.filter);
      if (i < 0) flag = true;
    }
    if (!flag)
      return (
        <li key={key}>
          <PostItem post={post} />
        </li>
      );
  });

  return <ul id="blog-list">{getList}</ul>;
}

export default BlogList;
