import { Link } from "gatsby";
import moment from "moment";
import TagList from "./tagList";

function PostItem(props) {
  let post = props.post.node;
  console.log(post);

  return (
    <div className="post-item">
      {post && 
      <>
      <h5>{moment(post.frontmatter.date, 'YYYY-M-D').format('MMM D, Y')}</h5>
      <Link to={post.fields.slug}>
        <h2>{post.frontmatter.title}</h2>
      </Link>
      <TagList tags={post.frontmatter.tags} />
      </>
      }
      
    </div>
  );
}

export default PostItem;
